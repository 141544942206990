<template>
  <div>
    <ejs-dialog
      ref="commonCodeDetailPopup"
      header="공통코드 상세"
      width="1400"
      height="800"
      :showCloseIcon="true"
      :allowDragging="true"
      :isModal="true"
      :animationSettings="animationSettings"
      :close="onCommonCodeDetailPopupClosed"
    >
      <div class="window commonCodeDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        공통코드 목록({{ groupCode }} / {{ groupName }})
                      </div>
                      <div class="header-caption">
                        [{{ numberWithCommas(dataSource.length) }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" v-model="useFlag" />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div class="lookup-lookup">
                        <erp-button
                            button-div="GET"
                            :is-shortcut-button="true"
                            :ignore="isPopupOpened"
                            @click.native="onViewCommonCode"
                        >
                          조회
                        </erp-button>
                      </div>
                      <ul class="header-button" style="margin-right: 140px">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :ignore="isPopupOpened"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="commonCodeDetailPopup.shortcuts.addCommonCodeDetail"
                              :shortcut="{key: 'F3'}"
                              :disabled="isModifyMode"
                              @click.native="onAddCommonCode"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              :disabled="isModifyMode"
                              :ignore="isPopupOpened"
                              @click.native="onDeleteCommonCode"
                          >
                            삭제
                          </erp-button>

                        </li>
                        <li class="reset">
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onResetCommonCode"
                          >
                            초기화
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onSortCommonCode"
                          >
                            자동정렬
                          </erp-button>
                        </li>
                      </ul>
                      <div class="header-switch">
                        <div class="title">
                          멀티 편집모드
                        </div>
                        <div class="switch">
                          <ejs-switch
                            v-model="isModifyMode"
                            :checked="isModifyMode"
                            @change="onModifySwitchChanged"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridOptions"
                        :dataSource="dataSource"
                        :selectionSettings="selectionSettings"
                        :allowFiltering="!isModifyMode"
                        :allowSorting="!isModifyMode"
                        :allowRowDragAndDrop="true"
                        :isAutoSelectCell="!isModifyMode"
                        @headerCellInfo="headerCellInfo"
                        @queryCellInfo="queryCellInfo"
                        @onGridDialogDoubleClickedOrEnterKeyed="onCommonCodeDetailGridDialogDoubleClickedOrEnterKeyed"
                        @recordClick="recordClick"
                        @cellSelected="gridCellSelected"
                        @rowDrop="gridRowDrop"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
            <common-code-attrb-popup
              v-if="isVisibleCommonCodeAttrbPopup"
              ref="commonCodeAttrbPopup"
              @popupClosed="closeCommonCodeAttrbPopup"
              @popupConfirmed="getCommonCodeDtl"
            />
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  @click.native="onSaveCommonCode"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseCommonCodeDetail"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import Vue from "vue";
import { numberWithCommas } from "@/utils/number";
import { getCommonCodeDtl, putCommonCodeDtl } from "@/api/commonCode";
import {
  Edit,
  Selection,
  Resize,
  Sort,
  Filter,
  RowDD
} from "@syncfusion/ej2-vue-grids";
import commonCodeAttrbPopup from "./CommonCodeAttrbPopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import _ from "lodash";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "commonCodeDetailPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    commonCodeAttrbPopup,
    editMultipleColumnsPopup,
    ejsGridWrapper,
    ErpButton,
  },
  computed: {
    isPopupOpened() {
      return (
        this.isVisibleCommonCodeAttrbPopup ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
    gridOptions() {
      return {
        provides: [Edit, Selection, Resize, Sort, Filter, RowDD],
        rowHeight: 24,
        allowResizing: true,
        isNOColumnDisplay: false,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "groupCode",
            allowEditing: false,
            visible: false,
          },
          {
            field: "_rid",
            allowEditing: false,
            allowFiltering: false,
            allowSorting: false,
            headerText: "NO",
            width: "40",
            textAlign: "Center",
            isPrimaryKey: true,
          },
          {
            field: "comCode",
            headerText: "코드",
            type: "string",
            width: "122",
            textAlign: "Left",
            allowEditing: true,
          },
          {
            field: "comName",
            headerText: "코드명",
            type: "string",
            width: "131",
            textAlign: "Left",
            allowEditing: true,
          },
          {
            field: "defaultFlag",
            headerText: "기본값",
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
            isSelectAllColumn: true,
            textAlign: "Center",
            width: 80,
            allowEditing: true,
          },
          {
            field: "codeAbrv",
            headerText: "약어",
            type: "string",
            width: "78",
            textAlign: "Left",
            allowEditing: true,
          },
          {
            field: "sortNo",
            headerText: "정렬",
            allowFiltering: false,
            type: "number",
            width: "44",
            textAlign: "Center",
            isNumericType: true,
            inputNumberProperty: { min: 0, max: 1000, maxLength: 3 },
            allowEditing: true,
            multiEdit: {
              allowDot: false,
              allowMinus: false,
              displayComma: false,
            },
          },
          {
            field: "useFlag",
            headerText: "사용",
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
            isSelectAllColumn: true,
            textAlign: "Center",
            width: 60,
            allowEditing: true,
          },
          {
            field: "comCodeAttrbList",
            headerText: "속성",
            columns: this.attrColumns,
            width: "80",
            allowEditing: false,
            textAlign: "Left",
            visible: this.isVisibleAttrb,
          },
          {
            field: "addedAttrb",
            headerText: "속성추가",
            allowEditing: false,
            width: "100",
            allowFiltering: false,
            allowSorting: false,
            textAlign: "Center",
          },
          {
            field: "color",
            headerText: "",
            textAlign: "Center",
            width: "24",
            allowEditing: false,
            allowFiltering: false,
            allowSorting: false,
            template: this.colorDropdownTemplate,
          },
          {
            field: "colorValue",
            headerText: "컬러",
            type: "string",
            width: "75",
            allowEditing: false,
            textAlign: "Center",
            template: this.colorValueTemplate,
          },
          {
            field: "codeDesc",
            headerText: "설명",
            type: "string",
            textAlign: "Left",
            isRemarks: true,
          },
          {
            field: "bizCode",
            headerText: "사업장코드",
            type: "string",
            width: "90",
            textAlign: "Left",
            visible: this.isVisibleBizCode,
          },
          {
            field: "convCode1",
            headerText: "컨버젼(1)",
            type: "string",
            width: "90",
            textAlign: "Left",
          },
          {
            field: "convCode2",
            headerText: "컨버젼(2)",
            type: "string",
            width: "90",
            textAlign: "Left",
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            width: "74",
            allowEditing: false,
            textAlign: "Left",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            width: "134",
            allowEditing: false,
            textAlign: "Left",
          },
        ],
        validationRules: {
          comCode: {
            required: true,
            duplicateCheck: true,
          },
          comName: {
            required: true,
          },
          defaultFlag: {
            custom: {
              method: (args) => {
                return this.isDuplicatedDefaultFlag(args.defaultFlag);
              },
              message: "main.validationMessage.maxFlagCountMessage",
            },
          },
        },
      };
    },
  },
  data() {
    return {
      colorDropdownTemplate: () => {
        return {
          template: {
            extends: Vue.component("colorDropdownTemplate", {
              template:
                "<div><ejs-colorpicker v-model='data.colorValue' :change='onColorSelect' cssClass='body-data-colorpicker'/></div>",
              props: ["colorSelectedCallback", "eventBus"],
              created() {
                this.eventBus.$on("colorValueCleared", (rid) => {
                  if (rid === this.data._rid) {
                    this.data.colorValue = "#fff";
                  }
                });
              },
              beforeMount() {
                this.data.colorValue = this.data.colorValue
                  ? this.data.colorValue
                  : "#fff";
              },
              data() {
                return {
                  data: { colorValue: "#fff" },
                };
              },
              methods: {
                onColorSelect(args) {
                  this.colorSelectedCallback({
                    rowData: this.data,
                    event: args,
                  });
                },
              },
            }),
            propsData: {
              colorSelectedCallback: this.onChangeColorPicker,
              eventBus: this.commonCodeDetailPopupEventBus,
            },
          },
        };
      },
      colorValueTemplate: () => {
        return {
          template: {
            extends: Vue.component("colorValueTemplate", {
              components: { InputText, ErpButton },
              template:
                '<div><input-text v-model="data.colorValue" style="border: 0; margin: -2px;" disabled/><erp-button button-div="DELETE" class="delete-color-button" :use-syncfusion-component-style="false" @click="clearColorValue" v-if="data.colorValue"/></div>',
              props: ["onClearColorValueCallback"],
              data() {
                return {
                  data: {},
                };
              },
              methods: {
                clearColorValue() {
                  this.data.colorValue = null;
                  this.onClearColorValueCallback(this.data);
                },
              },
            }),
            propsData: {
              onClearColorValueCallback: this.onClearColorValue,
            },
          },
        };
      },
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      isVisibleCommonCodeAttrbPopup: false,
      isVisibleAttrb: false,
      isVisibleBizCode: false,
      groupCode: null,
      groupName: null,
      schemaName: null,
      columns: [],
      attrColumns: [],
      dataSource: [],
      comCodeAttrbIdxList: [],
      animationSettings: { effect: "None" },
      numericParams: {
        params: {
          validateDecimalOnType: true,
          decimals: 0,
          min: 0,
          format: "N",
        },
      },
      commonCodeDetailPopupEventBus: new Vue(),
      defaultFlagCount: 0,
      isEditMultipleColumnsPopupOpen: false,
      useFlag: true,
      isModifyMode: false,
    };
  },
  methods: {
    numberWithCommas,
    async onCommonCodeDetailGridDialogDoubleClickedOrEnterKeyed() {
      await this.onSaveCommonCode();
    },
    gridRowDrop() {
      this.$nextTick(()=>{
        this.onSortCommonCode();
      });
    },
    headerCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
            isSelectAllColumn,
          },
        },
        node,
      } = args;
      if (
        field === "comCode" ||
        field === "comName"
      ) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (field === "color") {
        node.classList.add(this.$t("className.grid.colorArea"));
      }
      if (
        !this.isModifyMode &&
        (args.cell.column.headerText === "속성" || args.cell.column.headerText === "속성추가")
      ) {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.gridOptions.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          if (!isSelectAllColumn) {
            node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
          }
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.grid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    queryCellInfo(args) {
      const {
        column: {field, headerText},
        cell,
        data,
      } = args;
      if (headerText.includes("속성")) {
        cell.classList.add(this.$t("className.grid.clickArea"));
        if (!cell.innerText) {
          cell.innerText = "추가";
        }
      }
      if (field === "comName" && data.colorValue) {
        cell.style.color = data.colorValue;
      }
      if (
        field === "comCode" ||
        field === "comName" ||
        field === "codeAbrv" ||
        field === "codeDesc" ||
        field === "bizCode" ||
        field === "sortNo" ||
        field === "defaultFlag" ||
        field === "useFlag" ||
        field === "convCode1" ||
        field === "convCode2" ||
        (field === "color" && !this.isModifyMode)
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !data.useFlag) {
        cell.style.textDecoration = "line-through";
        cell.style.textDecorationColor = "red";
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.gridOptions.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    recordClick(args) {
      const {
        column: {
          field
        },
        rowData,
        cellIndex
      } = args;

      console.log('0.===>', args);
      console.log('1.===>', field);
      console.log('2.===>', rowData);
      console.log('3.===>', cellIndex);

      const startIdx = this.gridOptions.columns.findIndex(
        (d) => d.field === "comCodeAttrbList"
      );
      const startIdxColumn = this.gridOptions.columns.find(
        (d) => d.field === "comCodeAttrbList"
      ).columns;
      const startIdxColumnLength = startIdxColumn ? startIdxColumn.length : 1;
      if (
        startIdx <= cellIndex &&
        cellIndex < startIdx + startIdxColumnLength
      ) {
        const index = cellIndex + 1 - startIdx;
        if (!rowData.comCode) {
          return this.errorToast("먼저 저장한 후 이용할 수 있는 기능입니다");
        }
        const sortNoList = this.dataSource.map((item) => {
          return { comCode: item.comCode, sortNo: item.sortNo };
        });
        const comCodeAttrbList = {
          field: "update",
          schemaName: this.schemaName,
          idx: index,
          sortNoList: sortNoList,
          ...rowData,
        };
        this.openCommonCodeAttrbPopup(comCodeAttrbList);
      } else if (cellIndex === startIdx + startIdxColumnLength) {
        if (!rowData.comCode) {
          return this.errorToast("먼저 저장한 후 이용할 수 있는 기능입니다");
        }
        const sortNoList = this.dataSource.map((item) => {
          return { comCode: item.comCode, sortNo: item.sortNo };
        });
        const comCodeAttrbList = {
          field: "create",
          schemaName: this.schemaName,
          idx:
            this.comCodeAttrbIdxList.length === 0
              ? 1
              : this.comCodeAttrbIdxList[this.comCodeAttrbIdxList.length - 1]
              .idx + 1,
          sortNoList: sortNoList,
          ...rowData,
        };
        this.openCommonCodeAttrbPopup(comCodeAttrbList);
      }
    },
    onCommonCodeDetailPopupClosed() {
      this.$emit("popupClosed");
    },
    onAddCommonCode() {
      let _rid;
      let _sortNo;
      if (this.dataSource.length > 0) {
        const currentViewRecords = this.$refs.grid.getBatchCurrentViewRecords();
        _rid =
          currentViewRecords.length > 0
            ? _.maxBy(currentViewRecords, "_rid")._rid
            : 0;
        _sortNo =
          currentViewRecords.length > 0
            ? _.maxBy(currentViewRecords, "sortNo").sortNo
            : 0;
      } else {
        const { addedRecords } = this.$refs.grid.getBatchChanges();
        _rid = addedRecords.length;
        _sortNo =
          addedRecords.length > 0 ? _.maxBy(addedRecords, "sortNo").sortNo : 0;
      }

      const addRecordObject = {
        _rid: _rid + 1, //NO
        groupCode: this.groupCode, // 그룹코드
        comCode: null, // 공통코드
        comName: null, // 공통코드명
        defaultFlag: false, // 기본값여부
        codeAbrv: null, // 코드약어
        sortNo: _sortNo + 1, // 정렬번호
        useFlag: true, // 사용
        colorValue: null, // 색상값
        codeDesc: null, // 코드설명
        bizCode: null, // 사업장코드
        convCode1: null, // 컨버전코드1
        convCode2: null, // 컨버전코드2
      };
      this.$refs.grid.addRecord(addRecordObject);

      const currentviewRecords = this.$refs.grid.getBatchCurrentViewRecords();
      const idxToSelect = currentviewRecords.findIndex(
        (record) => record._rid === addRecordObject._rid
      );
      const getFirstFieldName = this.$refs.grid.getColumnFieldNames()[1];
      this.$refs.grid.editCell(idxToSelect, getFirstFieldName);
    },
    onSortCommonCode() {
      if (this.dataSource.length < 1) {
        return this.errorToast("먼저 저장한 후 이용할 수 있는 기능입니다");
      }
      for (let i = 0; i < this.dataSource.length; i++) {
        this.$refs.grid.updateCell(i, "sortNo", i + 1);
      }
    },
    onDeleteCommonCode() {
      const currentViewRecords = this.$refs.grid.getBatchCurrentViewRecords();
      if (currentViewRecords.length > 0) {
        const getFirstFieldName = this.$refs.grid.getColumnFieldNames()[1];
        let selectedRowIndexes = this.$refs.grid.getSelectedRowIndexes() - 1;
        if (selectedRowIndexes < 0) {
          selectedRowIndexes = 0;
          this.$refs.grid.editCell(0, getFirstFieldName);
        }
        this.$refs.grid.deleteRecord();
        if (selectedRowIndexes > 0) {
          this.$refs.grid.editCell(selectedRowIndexes, getFirstFieldName);
        }
        this.infoToast(this.$t("main.popupMessage.deletedSave"));
      } else {
        this.errorToast(this.$t("main.popupMessage.noData"));
      }
    },
    onResetCommonCode() {
      this.$refs.grid.batchCancel();
    },
    async onViewCommonCode() {
      if (this.$refs.grid.isGridModified()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      await this.getCommonCodeDtl();
    },
    async onSaveCommonCode() {
      if (!this.validate()) {
        return;
      }

      const schemaName = this.schemaName;
      let deleteFlag = false;

      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.$refs.grid.getBatchChanges();

      if (deletedRecords.length > 0) {
        deleteFlag = true;
      }

      await putCommonCodeDtl(
          addedRecords,
          changedRecords,
          deletedRecords,
          deleteFlag,
          schemaName
      );

      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.getCommonCodeDtl(false);
    },
    onCloseCommonCodeDetail() {
      this.$refs.commonCodeDetailPopup.hide();
    },
    onChangeColorPicker({ rowData, event }) {
      const currentViewRecords = this.$refs.grid.getBatchCurrentViewRecords();
      const realTargetRowIndex = currentViewRecords.findIndex(
        ({ _rid: targetRid }) => rowData._rid === targetRid
      );
      if (realTargetRowIndex < 0) {
        return;
      }
      this.$refs.grid.updateCell(
        realTargetRowIndex,
        "colorValue",
        event.currentValue.hex
      );
    },
    onClearColorValue(rowData) {
      const currentViewRecords = this.$refs.grid.getBatchCurrentViewRecords();
      const realTargetRowIndex = currentViewRecords.findIndex(
        ({ _rid: targetRid }) => rowData._rid === targetRid
      );
      if (realTargetRowIndex < 0) {
        return;
      }
      this.$refs.grid.updateCell(realTargetRowIndex, "colorValue", null);
      this.commonCodeDetailPopupEventBus.$emit(
        "colorValueCleared",
        rowData._rid
      );
    },
    isValidRecords(records) {
      for (let i = 0; i < records.length; i++) {
        const { comCode, comName } = records[i];
        if (!comCode || !comName) {
          return false;
        }
      }
      return true;
    },
    isDuplicatedDefaultFlag(flag) {
      if (flag) {
        this.defaultFlagCount++;
      }
      return this.defaultFlagCount <= 1;
    },
    getDataSource(dataSourceList, createAttrCell) {
      this.isVisibleBizCode = this.schemaName.includes("COM");
      this.isVisibleAttrb = this.comCodeAttrbIdxList.length > 0;

      if (createAttrCell) {
        const subColumns = [];
        if (this.isVisibleAttrb) {
          this.comCodeAttrbIdxList.map((item) => {
            subColumns.push({
              headerText: `속성(${item.idx})`,
              idx: item.idx,
              field: `comCodeAttrbObj.${item.idx}`,
              type: "string",
              textAlign: "Center",
              width: "90",
              allowEditing: false,
              allowResizing: true,
            });
          });
        }

        this.attrColumns = subColumns;
      }

      this.dataSource = dataSourceList.map((item, index) => {
        const comCodeAttrbObj = {};
        if (this.isVisibleAttrb) {
          item.comCodeAttrbList.forEach(
            (i) => (comCodeAttrbObj[i.idx] = i.attrbName)
          );
        }
        if (this.isVisibleBizCode) {
          item.bizCode = item.bizCode || null;
        }
        item.codeAbrv = item.codeAbrv || null;
        item.sortNo = item.sortNo || 0;
        item.codeDesc = item.codeDesc || null;
        item.convCode1 = item.convCode1 || null;
        item.convCode2 = item.convCode2 || null;
        return {
          _rid: index + 1,
          comCodeAttrbObj,
          ...item,
        };
      });
    },
    async showCommonCodeDetailPopup(popupData) {
      this.groupCode = popupData.groupCode;
      this.groupName = popupData.groupName;
      this.schemaName = popupData.schemaName;
      this.$refs.commonCodeDetailPopup.show();
      await this.getCommonCodeDtl();
    },
    openCommonCodeAttrbPopup(comCodeAttrbList) {
      this.isVisibleCommonCodeAttrbPopup = true;
      this.$nextTick(() => {
        this.$refs.commonCodeAttrbPopup.openCommonCodeAttrbPopup(
          comCodeAttrbList
        );
      });
    },
    closeCommonCodeAttrbPopup() {
      this.isVisibleCommonCodeAttrbPopup = false;
    },
    async getCommonCodeDtl(createAttrCell = true) {
      const res = await getCommonCodeDtl(this.groupCode, this.schemaName);
      let dataSourceList = res.value.comCodeDtlList;
      if (this.useFlag) {
        dataSourceList = dataSourceList.filter((item) => item.useFlag);
      }
      this.comCodeAttrbIdxList = res.value.comCodeAttrbIdxList;
      this.getDataSource(dataSourceList, createAttrCell);
    },
    validate() {
      this.defaultFlagCount = 0;
      return this.$refs["grid"].validate();
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.grid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {type: 'Multiple', mode: 'Both', enableToggle: false};
      this.$refs.grid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async gridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.gridOptions.columns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field);
      const column = columns[cellIndex];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
      );
    },
  },
};
</script>
