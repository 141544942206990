<template>
  <div>
    <ejs-dialog
      ref="commonCodeAttrbPopup"
      header="공통코드 속성"
      width="1000"
      height="800"
      :showCloseIcon="true"
      :allowDragging="true"
      :isModal="true"
      :animationSettings="animationSettings"
      :close="onCommonCodeAttrbPopupClosed"
    >
      <div class="window commonCodeAttribute">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        속성 IDX
                        {{
                          commonCodeAttrbInfo.idx
                            ? `= ${commonCodeAttrbInfo.idx}`
                            : null
                        }}
                      </div>
                      <div class="header-caption">
                        [{{ numberWithCommas(commonCodeAttrb.length) }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" v-model="useFlag"/>
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="header-button" style="margin-right: 140px">
                        <li class="search">
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              :is-shortcut-button="true"
                              @click.native="onViewCommonAttrb"
                          >
                            조회
                          </erp-button>
                        </li>
                        <li class="reset">
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onResetCommonCode"
                          >
                            초기화
                          </erp-button>
                        </li>
                      </ul>
                      <div class="header-switch">
                        <div class="title">
                          멀티 편집모드
                        </div>
                        <div class="switch">
                          <ejs-switch
                            v-model="isModifyMode"
                            :checked="isModifyMode"
                            @change="onModifySwitchChanged"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="commonCodeAttrbGrid"
                        v-bind="gridOptions"
                        :dataSource="commonCodeAttrb"
                        :selectionSettings="selectionSettings"
                        :allowFiltering="!isModifyMode"
                        :allowSorting="!isModifyMode"
                        :isAutoSelectCell="!isModifyMode"
                        @recordClick="recordClick"
                        @headerCellInfo="headerCellInfo"
                        @queryCellInfo="queryCellInfo"
                        @cellSelected="gridCellSelected"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveCommonAttrb"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseCommonAttrb"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { putCommonCodeAttrb, getCommonCodeAttrb } from "@/api/commonCode";
import { isGridModified } from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import {
  Edit,
  Selection,
  Resize,
  Sort,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "CommonCodeAttrbPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    editMultipleColumnsPopup,
    ErpButton,
  },
  computed: {
    gridOptions() {
      return {
        provides: [Edit, Selection, Resize, Sort, Filter],
        allowResizing: true,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        isInPopup: false,
        columns: [
          {
            field: "groupCode",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "idx",
            visible: false,
          },
          {
            field: "attrbId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "comCode",
            allowEditing: false,
            headerText: "공통코드",
            width: "120",
            type: "string",
            textAlign: "Left",
          },
          {
            field: "comName",
            allowEditing: false,
            headerText: "코드명",
            width: "127",
            type: "string",
            textAlign: "Left",
          },
          {
            field: "attrb",
            headerText: "속성ID",
            width: "87",
            type: "string",
            textAlign: "Left",
            allowEditing: true,
          },
          {
            field: "attrbName",
            headerText: "속성명",
            width: "143",
            type: "string",
            textAlign: "Left",
            maxLength: 100,
            allowEditing: true,
          },
          {
            field: "sortNo",
            headerText: "정렬",
            allowFiltering: false,
            width: "51",
            type: "number",
            textAlign: "Center",
            isNumericType: true,
            allowEditing: true,
            multiEdit: {
              allowDot: false,
              allowMinus: false,
              displayComma: false,
            },
          },
          {
            field: "remove",
            headerText: "",
            allowEditing: false,
            width: "24",
            allowFiltering: false,
            allowSorting: false,
            textAlign: "Center",
          },
          {
            field: "attrbDesc",
            headerText: "속성설명",
            width: "200",
            type: "string",
            textAlign: "Left",
            isRemarks: true,
            maxLength: 200,
            allowEditing: true,
          },
        ],
      };
    },
  },
  data() {
    return {
      useFlag: true,
      grid: [Edit, Selection, Resize, Sort, Filter],
      commonCodeAttrb: [],
      commonCodeAttrbInfo: {},
      animationSettings: { effect: "None" },
      filterSettings: { type: "Menu" },
      numericParams: {
        params: {
          validateDecimalOnType: true,
          decimals: 0,
          min: 0,
          format: "N",
        },
      },
      selectionSettings: { type: "Single", enableToggle: false },
      isEditMultipleColumnsPopupOpen: false,
      isModifyMode: false,
    };
  },
  methods: {
    numberWithCommas,
    async recordClick(args) {
      if (
        args.column &&
        args.column.field === "remove" &&
        args.rowData.attrbId &&
        !this.isModifyMode
      ) {
        if (await this.confirm("초기화하시겠습니까?")) {
          await this.onDeleteCommonAttrb(args.rowData.attrbId);
        }
      }
    },
    headerCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
            isSelectAllColumn,
          },
        },
        node,
      } = args;
      if (
        field === "attrb" ||
        field === "attrbName"
      ) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (field === "remove") {
        node.classList.add(this.$t("className.grid.deleteArea"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.gridOptions.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field)
          ?.concat(['remove']);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          if (!isSelectAllColumn) {
            node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
          }
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.commonCodeAttrbGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      if (column?.field === 'remove') {
        await this.deleteCommonAttrb(selectedRowIndexes);
      } else {
        this.isEditMultipleColumnsPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
        );
      }
    },
    async deleteCommonAttrb(indexes) {
      const gridRefs = this.$refs.commonCodeAttrbGrid;
      for (const index of indexes) {
        const attrbId = gridRefs?.getBatchCurrentViewRecords()[index]?.attrbId;
        if (attrbId) {
          await this.onDeleteCommonAttrb(attrbId);
        }
      }
    },
    queryCellInfo(args) {
      const {
        column: {field},
        cell,
      } = args;
      if (field === "remove") {
        cell.classList.add(this.$t("className.grid.deleteArea"));
      }
      if (
        field === "attrb" ||
        field === "attrbName" ||
        field === "sortNo" ||
        field === "attrbDesc"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.gridOptions.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field)
          ?.concat(['remove']);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    async openCommonCodeAttrbPopup(popupData) {
      this.commonCodeAttrbInfo = popupData;
      await this.getCommonCodeAttrb();
      this.$refs.commonCodeAttrbPopup.show();
      if (!this.isModifyMode) {
        this.$refs.commonCodeAttrbGrid.selectCell(
          {rowIndex: 1, cellIndex: 6},
          false
        );
      }
    },
    closeCommonCodeAttrbPopup() {
      this.$emit("popupClosed");
    },
    async onViewCommonAttrb() {
      if (isGridModified(this.$refs.commonCodeAttrbGrid)) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      await this.getCommonCodeAttrb();
    },
    onCloseCommonAttrb() {
      this.$refs.commonCodeAttrbPopup.hide();
    },
    onResetCommonCode() {
      this.$refs.commonCodeAttrbGrid.batchCancel();
    },
    async onSaveCommonAttrb() {
      const {
        changedRecords,
      } = this.$refs.commonCodeAttrbGrid.getBatchChanges();

      const addedChangedDeletedAttrb = {
        addedRecords: changedRecords
          .filter((code) => !code.attrbId)
          .map((map) => {
            if (!map.idx) {
              map.idx = this.commonCodeAttrbInfo.idx;
            }
            return map;
          }),
        changedRecords: changedRecords.filter((code) => code.attrbId),
      };

      await putCommonCodeAttrb(
          addedChangedDeletedAttrb.addedRecords,
          addedChangedDeletedAttrb.changedRecords,
          undefined,
          this.commonCodeAttrbInfo.schemaName
      );

      this.infoToast(this.$t("main.popupMessage.saved"));
      this.$emit("popupConfirmed");
      await this.getCommonCodeAttrb();
    },
    async onDeleteCommonAttrb(deletedRecord) {
      await putCommonCodeAttrb(
          undefined,
          undefined,
          [{ attrbId: deletedRecord }],
          this.commonCodeAttrbInfo.schemaName
      );
      this.infoToast(this.$t("main.popupMessage.cleared"));
      this.$emit("popupConfirmed");
      await this.getCommonCodeAttrb();
    },
    async getCommonCodeAttrb() {
      const response = await getCommonCodeAttrb(
        this.commonCodeAttrbInfo.groupCode,
        this.commonCodeAttrbInfo.schemaName,
        this.commonCodeAttrbInfo.idx
          ? this.commonCodeAttrbInfo.idx
          : this.commonCodeAttrbInfo._rid
      );
      const commonCodeAttrbList = response.value.comCodeAttrbList.map(
          (record) => {
            {
              if (!record["sortNo"]) {
                // sortNo 정렬 중요도 1. attrbPopup에 정렬번호가 존재함 > 2. detailPopup에 정렬번호가 존재함 > 3. 1,2를 제외하고 sortNo가 없을 때는 자동정렬
                this.commonCodeAttrbInfo.sortNoList.forEach((item) => {
                  if (item.comCode === record["comCode"]) {
                    record["sortNo"] = item.sortNo;
                  }
                });
              }
              return { ...record };
            }
          }
      );
      this.commonCodeAttrb = commonCodeAttrbList.filter(item => this.useFlag ? item.useFlag === this.useFlag : true).sort(
          (a, b) => a.sortNo - b.sortNo
      );
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.commonCodeAttrbGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {type: 'Single', enableToggle: false};
      this.$refs.commonCodeAttrbGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async gridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.gridOptions.columns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field)
        ?.concat(['remove']);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      if (column?.field === 'remove') {
        await this.deleteCommonAttrb(selectedRowIndexes);
      } else {
        this.isEditMultipleColumnsPopupOpen = true;
        await this.$nextTick();
        this.$refs.editMultipleColumnsPopup.showPopup(
          column,
          selectedRowIndexes
        );
      }
    },
    onCommonCodeAttrbPopupClosed() {
      this.$emit("popupClosed");
    }
  },
};
</script>
