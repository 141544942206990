<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field dbSchema">
            <div class="title">DB 스키마</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="commonCodeMstInfo.schemaName"
                  :dataSource="schemaNameOptions"
                  :change="onSchemaNameChanged"
                  :allowFiltering="false"
                  :fields="{ text: 'comName', value: 'comCode' }"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field groupCodeGroupCodeName">
            <div class="title">그룹코드/그룹코드명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  id="groupName"
                  style="width: 200px"
                  v-model="commonCodeMstInfo.groupName"
                  @focus="onGroupCodeNameClear"
                  @keydown.native="onGroupCodeNameKeyDown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="true"
                        v-model="commonCodeMstInfo.useFlag"
                      />
                      <i></i>
                      <div class="label">사용여부</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">그룹코드 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-key-color="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onSaveCommonCode"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      :disabled="isModifyMode"
                      shortcut-key="commonCodeRegistration.shortcuts.addCommonCode"
                      :shortcut="{key: 'F3'}"
                      @click.native="onAddCommonCode"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      :disabled="isModifyMode"
                      @click.native="onDeleteCommonCode"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onResetCommonCode"
                  >
                    초기화
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                v-bind="gridOptions"
                :dataSource="commonCodeMst"
                :selectionSettings="selectionSettings"
                :allowFiltering="!isModifyMode"
                :allowSorting="!isModifyMode"
                :isAutoSelectCell="!isModifyMode"
                @recordClick="recordClick"
                @cellEdit="cellEdit"
                @cellSaved="cellSaved"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @actionComplete="gridActionComplete"
                @cellSelected="gridCellSelected"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <common-code-detail-popup
      ref="commonCodeDetailPopup"
      v-if="isVisibleCommonCodeDetailPopup"
      @popupClosed="closeCommonCodeDetailPopup"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.dbSchema .content .item {width: 120px;}
body .appContent .lookup-condition .field.groupCodeGroupCodeName .content .item {width: 200px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  Edit,
  ForeignKey,
  Resize,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import commonCodeDetailPopup from "../popup/CommonCodeDetailPopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import { maxBy as _maxBy } from "lodash";
import { getCommonCodeMst, putCommonCodeMst } from "@/api/commonCode";
import {getCommonCodesInfo, getSchemaInfo} from "@/api/common";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import { isGridModified } from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesLoadCommonCodesMap,
  commonCodesSetCommonCode
} from "@/utils/commonCodes";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "CommonCodeRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputText,
    commonCodeDetailPopup,
    editMultipleColumnsPopup,
    ejsGridWrapper,
    ErpButton,
  },
  async created() {
    this.commonCodeMstInfo.schemaName = getCurrentBizCode();
    await this.getSchemaInfo();
  },
  data() {
    return {
      count: 0,
      commonCodeMstLength: 0,
      isVisibleCommonCodeDetailPopup: false,
      isEditMultipleColumnsPopupOpen: false,
      commonCodeMst: [],
      commonCodeMstInfo: {
        sortNo: null, // 정렬번호
        groupCode: null, // 그룹코드
        groupName: null, // 그룹명
        jobDiv: null, // 업무구분
        useFlag: true, // 사용여부
        codeDesc: null, // 코드설명
        insertId: null, // 등록자
        insertName: null, // 등록자명
        insertDt: null, // 등록일시
        schemaName: null, // 스키마이름
      },
      savedGroupCode: null,
      schemaNameOptions: [],
      selectionSettings: {
        type: "Single",
      },
      editableFields: {},
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isVisibleCommonCodeDetailPopup ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
    gridOptions() {
      return {
        provides: [Edit, ForeignKey, Resize, ExcelExport],
        allowResizing: true,
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "deleteFlag",
            headerText: "삭제",
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
            textAlign: "center",
            minWidth: 16,
            width: 60,
            isSelectAllColumn: true,
          },
          {
            headerText: "그룹코드",
            textAlign: "Left",
            type: "string",
            field: "groupCode",
            allowEditing: true,
            isPrimaryKey: true,
            width: 200,
            maxLength: 20,
          },
          {
            headerText: "그룹코드명",
            textAlign: "Left",
            type: "string",
            field: "groupName",
            allowEditing: true,
            width: 200,
            maxLength: 100,
          },
          {
            headerText: "업무구분",
            textAlign: "Left",
            type: "string",
            field: "jobDiv",
            allowEditing: true,
            edit: { params: { sortOrder: "none" } },
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "JOB_DIV",
            width: 200,
          },
          {
            headerText: "설명",
            textAlign: "Left",
            type: "string",
            field: "codeDesc",
            allowEditing: true,
            isRemarks: true,
          },
          {
            field: "useFlag",
            headerText: "사용",
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
            isSelectAllColumn: true,
            textAlign: "Center",
            width: 60,
          },
          {
            headerText: "등록자",
            textAlign: "Left",
            type: "string",
            field: "insertName",
            allowEditing: false,
            width: 120,
          },
          {
            headerText: "등록일시",
            textAlign: "Center",
            type: "string",
            field: "insertDt",
            allowEditing: false,
            width: 130,
          },
        ],
        validationRules: {
          groupCode: {
            required: true,
            duplicateCheck: true,
            maxLength: 20,
          },
          groupName: {
            required: true,
            maxLength: 100,
          },
          jobDiv: {
            required: true,
          },
        },
      };
    },
  },
  methods: {
    numberWithCommas,
    headerCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
            isSelectAllColumn,
          },
        },
        node,
      } = args;
      if (
        field === "groupCode" ||
        field === "groupName" ||
        field === "jobDiv"
      ) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (field === "groupCode") {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.gridOptions.columns
          ?.filter(item => item.allowEditing && item.field !== 'groupCode')
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          if (!isSelectAllColumn) {
            node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
          }
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.grid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    queryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (field === "groupCode") {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (
        [
          "deleteFlag",
          "jobDiv",
          "useFlag",
          "groupCode",
          "groupName",
          "codeDesc",
        ].includes(field)
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !data.useFlag) {
        cell.style.textDecoration = "line-through";
        cell.style.textDecorationColor = "red";
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.gridOptions.columns
          ?.filter(item => item.allowEditing && item.field !== 'groupCode')
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    recordClick(args) {
      this.closeCommonCodeDetailPopup();

      if (args.column?.field === "groupCode" && args.rowData.groupName) {
        const popupData = {
          groupCode: args.rowData.groupCode,
          groupName: args.rowData.groupName,
          schemaName: this.commonCodeMstInfo.schemaName,
        };
        this.openCommonCodeDetailPopup(popupData);
      }

      this.savedGroupCode = args.rowData?.groupCode;
    },
    cellEdit(args) {
      this.savedGroupCode = args.rowData?.groupCode;
    },
    cellSaved(args) {
      if (args.columnName === "groupCode" && args.value) {
        this.savedGroupCode = args.value;
      }
    },
    openCommonCodeDetailPopup(popupData) {
      this.isVisibleCommonCodeDetailPopup = true;
      this.$nextTick(() => {
        this.$refs.commonCodeDetailPopup.showCommonCodeDetailPopup(popupData);
      });
    },
    closeCommonCodeDetailPopup() {
      this.isVisibleCommonCodeDetailPopup = false;
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.grid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    async onSchemaNameChanged(args) {
      this.commonCodeMstInfo.schemaName = args.itemData.value;
      await this.getCommonCodeMst();
    },
    onGroupCodeNameClear() {
      this.commonCodeMstInfo.bcncNamebizNo = null;
    },
    async onGroupCodeNameKeyDown(event) {
      if (event.key === "Enter") {
        await this.getCommonCodeMst();
      }
    },
    async onViewButtonClicked() {
      if (isGridModified(this.$refs.grid)) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      await this.getCommonCodeMst();
    },
    onAddCommonCode() {
      let _rid;
      if (this.commonCodeMst.length > 0) {
        const currentViewRecords = this.$refs.grid.getBatchCurrentViewRecords();
        _rid = _maxBy(currentViewRecords, "_rid")._rid;
      } else {
        const { addedRecords } = this.$refs.grid.getBatchChanges();
        _rid = addedRecords.length;
      }

      const addRecordObject = {
        _rid: _rid + 1,
        deleteFlag: null,
        groupCode: null,
        groupName: null,
        jobDiv: commonCodeGetComCodeDefaultValue("JOB_DIV"),
        codeDesc: null,
        sortNo: null,
        useFlag: true,
      };
      this.$refs.grid.addRecord(addRecordObject);

      const currentviewRecords = this.$refs.grid.getBatchCurrentViewRecords();
      const idxToSelect = currentviewRecords.findIndex(
        (record) => record._rid === addRecordObject._rid
      );

      this.$refs.grid.editCell(idxToSelect, "groupCode");
    },
    async onDeleteCommonCode() {
      const { addedRecords } = this.$refs.grid.getBatchChanges();

      const currentviewRecords = this.$refs.grid.getBatchCurrentViewRecords();
      let deletedRecords = currentviewRecords.filter((item) => item.deleteFlag);
      if (deletedRecords.length < 1) {
        return this.errorToast(this.$t("main.popupMessage.noDataToDelete"));
      }

      if (!(await this.confirm(this.$t("main.popupMessage.confirmDelete")))) {
        return;
      }

      if (addedRecords.length > 0) {
        deletedRecords = deletedRecords.filter(
          (item) =>
            !addedRecords.map((i) => i.groupCode).includes(item.groupCode)
        );
      }

      if (deletedRecords.length < 1) {
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.getCommonCodeMst();

        return;
      }
      await putCommonCodeMst(
          [],
          [],
          deletedRecords,
          true,
          this.commonCodeMstInfo.schemaName
      );
      this.infoToast(this.$t("main.popupMessage.saved"));

      // common code reload in /src/store/index.js
      const {
        data: { commonCodes },
      } = await getCommonCodesInfo();

      // 현재 BIZ_CODE에 따라서 공통코드 셋팅.(DB스키마 COMMON의 공통코드 데이터를 위함)
      for (const [key, value] of Object.entries(commonCodes)) {
        Object.assign(
          commonCodes,
          Object.fromEntries(
            new Map(
              [
                [
                  key,
                  value.filter(v => v.bizCode === getCurrentBizCode() || v.bizCode === "%" || !v.bizCode)
                ]
              ]
            )
          )
        );
      }

      // 공통코드 저장
      commonCodesSetCommonCode(commonCodes);
      commonCodesLoadCommonCodesMap();

      await this.getCommonCodeMst();
    },
    onResetCommonCode() {
      this.$refs.grid.batchCancel();
    },
    async onSaveCommonCode() {
      const schemaName = this.commonCodeMstInfo.schemaName;
      let deleteFlag = false;
      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.$refs.grid.getBatchChanges();

      if (
        addedRecords.length === 0 &&
        changedRecords.length === 0 &&
        deletedRecords.length === 0
      ) {
        this.errorToast("변경사항이 없습니다");
        return;
      }

      if (!this.$refs["grid"].validate()) {
        return;
      }

      if (deletedRecords.length > 0) {
        deleteFlag = true;
      }

      await putCommonCodeMst(
          addedRecords,
          changedRecords,
          deletedRecords,
          deleteFlag,
          schemaName
      );
      this.infoToast(this.$t("main.popupMessage.saved"));

      const {
        data: { commonCodes },
      } = await getCommonCodesInfo();

      // 현재 BIZ_CODE에 따라서 공통코드 셋팅.(DB스키마 COMMON의 공통코드 데이터를 위함)
      for (const [key, value] of Object.entries(commonCodes)) {
        Object.assign(
          commonCodes,
          Object.fromEntries(
            new Map(
              [
                [
                  key,
                  value.filter(v => v.bizCode === getCurrentBizCode() || v.bizCode === "%" || !v.bizCode)
                ]
              ]
            )
          )
        );
      }

      // 공통코드 저장
      commonCodesSetCommonCode(commonCodes);
      commonCodesLoadCommonCodesMap();

      await this.getCommonCodeMst();
    },
    async getSchemaInfo() {
      const response = await getSchemaInfo();
      const schemaName = [];
      for (let i = 0; i < response.value.schemaInfoList.length; i++) {
        schemaName.push(response.value.schemaInfoList[i].schemaName);
      }
      this.schemaNameOptions = schemaName;
    },
    async getCommonCodeMst() {
      const groupCode = this.commonCodeMstInfo.groupCode;
      const groupName = this.commonCodeMstInfo.groupName;
      const schemaName = this.commonCodeMstInfo.schemaName;
      const useFlag = this.commonCodeMstInfo.useFlag ? true : null;

      const response = await getCommonCodeMst(groupCode, groupName, schemaName, useFlag);
      this.commonCodeMst = response.value.comCodeMstList.map(
          (item, index) => {
            item.deleteFlag = item.deleteFlag || null;
            item.codeDesc = item.codeDesc || null;
            return {
              _rid: index + 1,
              ...item,
            };
          }
      );
      this.commonCodeMstLength = this.commonCodeMst.length;
    },
    gridActionComplete(args) {
      this.count = numberWithCommas(this.$refs.grid?.getGridBatchCount() || 0);
      if (this.savedGroupCode) {
        const findIndex = args.rows.findIndex(
          (item) => item.data.groupCode === this.savedGroupCode
        );
        this.$refs.grid.selectRow(findIndex);
        this.savedGroupCode = null;
      }
    },
    onClickExcel() {
      this.$refs.grid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {type: 'Single'};
      this.$refs.grid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async gridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.gridOptions.columns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing && item.field !== 'groupCode')
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
  },
};
</script>
